






























import { Component, Vue } from "vue-property-decorator";
import SettingsBaseView from "@/views/account/settings/SettingsBaseView.vue";
import AccountSettingsDataContext from "@/dataContexts/AccountSettingsDataContext";
import { ValidationObserver } from "vee-validate";
import Notification from "@/common/Notification";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import AccountErrors from "@/common/AccountErrors";
import { InputComponent } from "keiryo";
import CurrentUser from "@/common/CurrentUser";

@Component({
    components: {
        SettingsBaseView,
        InputComponent
    }
})
export default class AccountDataView extends Vue {
    private accountSettingsDataContext: AccountSettingsDataContext = new AccountSettingsDataContext();

    private loading = false;

    private userName = "";
    private email = "";

    mounted() {
        this.loading = true;

        this.accountSettingsDataContext.getAccountData().then(x => {
            if (x.successfully && x.data) {
                this.userName = x.data.userName;
                this.email = x.data.email;
            }
        }).finally(() => this.loading = false);
    }

    private async onSubmit() {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

        if (await observer.validate()) {
            this.loading = true;

            const saveResult = await this.accountSettingsDataContext.saveAccountData(this.userName, this.email);

            if (saveResult.successfully) {
                await CurrentUser.loadUserInfo();
                Notification.addSuccess(TranslationUtils.translate("view.settings.accountData.savedSuccessfully")).show();
            } else {
                if (saveResult.data) {
                    let errorContentKey: string|undefined;

                    switch (saveResult.data) {
                        case AccountErrors.DuplicateUserName:
                            errorContentKey = "view.settings.accountData.duplicateUserName";
                            break;

                        case AccountErrors.FaultyEmail:
                            errorContentKey = "view.settings.accountData.faultyEmail";
                            break;

                        default:
                            break;
                    }

                    if (errorContentKey) {
                        Notification.addError(TranslationUtils.translate(errorContentKey), false).show();
                    }
                }
            }

            this.loading = false;
        }
    }
}
