


























import { Component, Vue } from "vue-property-decorator";
import TabsHubTab from "@/common/models/TabsHubTab";
import TabsHubComponent from "@/components/global/TabsHubComponent.vue";
import TranslationUtils from "@/common/utilities/TranslationUtils";

@Component({
    components: {
        TabsHubComponent
    }
})
export default class SettingsBaseView extends Vue {
    private tabs: TabsHubTab[] = [];

    created() {
        this.tabs = [
            new TabsHubTab({ label: TranslationUtils.translate("view.settings.tabsHub.accountData"), url: "/settings/accountData" }),
            new TabsHubTab({ label: TranslationUtils.translate("view.settings.tabsHub.changePassword"), url: "/settings/changePassword" })
        ];
    }
}
